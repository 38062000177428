import { configureStore as configureStoreRTK } from '@reduxjs/toolkit'
// eslint-disable-next-line sonar/no-wildcard-import
import * as actionCreators from '../redux/actions'
import { rootReducer } from '../redux/slices'

const configureStore = () =>
    configureStoreRTK({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
        devTools: {
            actionCreators,
            trace: true,
            traceLimit: 25,
        },
    })

const store = configureStore()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
