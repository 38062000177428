import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SpinnerState {
    showSpinner: boolean
}
const initialState: SpinnerState = {
    showSpinner: false,
}

const spinnerSlice = createSlice({
    name: 'spinner',
    initialState,
    reducers: {
        setShowSpinner: (state: SpinnerState, action: PayloadAction<boolean>) => {
            state.showSpinner = action.payload
        },
    },
    selectors: {
        showSpinnerSelector: (state: SpinnerState) => state.showSpinner,
    },
})

export const { setShowSpinner } = spinnerSlice.actions
export const { showSpinnerSelector } = spinnerSlice.selectors

export default spinnerSlice
