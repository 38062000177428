const ARIA_EXPANDED = 'aria-expanded'
const TOGGLE_ARROW_CLASS = 'accordion-tab__toggle-arrow'
const ACCORDION_TAB_CLASS = 'accordion-container__accordion-tab'
const ANSWER_WRAPPER_CLASS = 'accordion-tab__answer-wrapper'
const QUESTION_WRAPPER_CLASS = 'accordion-tab__question-wrapper'

let isInitialized = false

const accordion = (): void => {
    if (isInitialized) {
        return
    }

    const toggleAccordion = (accordionTab: Element) => {
        const answerWrapper = accordionTab.querySelector(`.${ANSWER_WRAPPER_CLASS}`) as HTMLDivElement
        const toggleArrow = accordionTab.querySelector(`.${TOGGLE_ARROW_CLASS}`) as HTMLAnchorElement
        const isExpanded = answerWrapper.getAttribute(ARIA_EXPANDED) === 'true'

        answerWrapper.setAttribute(ARIA_EXPANDED, String(!isExpanded))
        answerWrapper.style.height = isExpanded ? '0' : `${answerWrapper.scrollHeight}px`

        toggleArrow.classList.toggle('arrow-up', !isExpanded)
        toggleArrow.classList.toggle('arrow-down', isExpanded)
    }

    const toggleHandler = (event: MouseEvent) => {
        const target = event.target as HTMLElement

        const isToggle = target.classList.contains(TOGGLE_ARROW_CLASS)
        const isInsideQuestion = target.closest(`.${QUESTION_WRAPPER_CLASS}`)

        if (isToggle || isInsideQuestion) {
            event.preventDefault()

            const accordionTab = target.closest(`.${ACCORDION_TAB_CLASS}`)
            if (accordionTab) {
                toggleAccordion(accordionTab)
            }
        }
    }

    const keyboardHandler = (event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
            toggleHandler(event as unknown as MouseEvent)
        }
    }

    document.addEventListener('click', toggleHandler as EventListener)
    document.addEventListener('keydown', keyboardHandler as EventListener)

    isInitialized = true
}

document.addEventListener('DOMContentLoaded', accordion)

export { accordion }
