import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import store from '../store'
import { ComponentType, CompProps } from './cms.helper.type'
import { staticComponentMap } from '../globalConstants/authorableComponents.constant'
import { staticFunctionMap } from '../globalConstants/cmsStaticCompInit.constant'

/**
 * Initialize React component
 * @param {ComponentType} component - Component object
 * @param {React.FC<CompProps>} Comp - React functional component
 * @returns {void} void
 */
const initReactComponent = (component: ComponentType, Comp: React.FC<CompProps>): void => {
    try {
        const root = ReactDOM.createRoot(component.element)
        root.render(
            <React.StrictMode>
                <Provider store={store}>
                    <Comp {...component.props}>{component.element.innerHTML}</Comp>
                </Provider>
            </React.StrictMode>,
        )
    } catch {
        console.error(`failed to initialize react component: ${component.name}`)
    }
}

/**
 * Render the authored elements
 * @param {ComponentType} component - Component object
 * @returns {void} void
 */
export const renderDynamicReactComponent = (component: ComponentType): void => {
    const Comp = staticComponentMap[component.name] as React.FC<CompProps>
    if (Comp) {
        initReactComponent(component, Comp)
    } else {
        // component definition is not found in the static component list, throwing an error on console
        console.error(`Element name didn't match *** ${component.name} *** `)
    }
}

/**
 * Render static components.
 * @param {Document | HTMLElement} ref - Document or HTMLElement
 * @returns {void} void
 */
export const renderAEMComponents = (ref: Document | HTMLElement): void => {
    Object.keys(staticFunctionMap).forEach((key: string) => {
        const singleStaticComponentInstances = ref.querySelectorAll(`.${key}`)
        singleStaticComponentInstances.forEach(el => {
            const { method, param } = staticFunctionMap[key]
            method(el, param)
        })
    })
}
